export const isPrivatePage = (uri: string): boolean => {
  // No private pages on debug environments
  if (process.env.DEBUG_MODE === 'true') return false;

  // If the page uri starts with an underscore, then it's a private page.
  if (/^_/.test(uri)) return true;

  // Default state is not private.
  return false;
};
