import Image from 'next/image';
import Link from 'next/link';
import { BodyContentModulesFreeformGraphicAndText, MastheadContentModulesFreeformGraphicAndText } from '@/types/pbskids-graph';
import PictureSource from '@/components/base/PictureSource';
import { buildCardLink } from '@/components/modules/MediaList/LinkCard';
import React from 'react';
import styles from './FreeForm.module.scss';

interface Props {
  pageData: BodyContentModulesFreeformGraphicAndText | MastheadContentModulesFreeformGraphicAndText,
  inMasthead?: boolean,
}

const FreeFormModule: React.FC<Props> = ({
  pageData,
  inMasthead = false,
}) => {
  const InnerContent = () => {
    return (pageData.backgroundImage?.[0]?.url && pageData.smallScreenBackgroundImage?.[0]?.url) &&
    <>
      <picture className={styles.backgroundImages}>
        <PictureSource
          media='(max-width: 567px)'
          src={pageData.smallScreenBackgroundImage[0].url}
          width={334}
          height={251}
        />
        <Image
          src={pageData.backgroundImage[0].url}
          alt={pageData.target?.[0]?.title || ''}
          loading={inMasthead ? 'eager' : 'lazy'}
          priority={inMasthead}
          width={964}
          height={270}
          sizes='(min-width: 1280px) 964px, (min-width: 1024px) 934px, 538px'
        />
      </picture>

      {/* Linear gradient if either heading is present to increase readability */}
      <div className={`${pageData.subheading || pageData.heading ? styles.backgroundGradient : ''}`}></div>

      {/* Content */}
      <div className={styles.messageContainer}>
        {/* Display logo image if present */}
        {pageData.logoImage?.[0]?.url ?
          <Image
            className={styles.logoImage}
            src={pageData.logoImage[0].url}
            width={380}
            height={225}
            alt=''
          /> : pageData.heading &&
          <h2 className={styles.upperText}>
            {pageData.heading}
          </h2>
        }
        { pageData.subheading &&
          <h3 className={styles.lowerText}>
            {pageData.subheading}
          </h3>
        }
      </div>
    </>;
  };

  const SectionContent = () => {
    if (pageData.target?.[0]) {
      return (
        <Link
          href={buildCardLink(pageData.target[0]) || '#'}
          className={`${styles.imageContainer} ${inMasthead ? styles.inMasthead : ''}`}
          prefetch={ false }
          aria-label={ 'Visit ' + (pageData.target[0]?.title || '') }
        >
          <InnerContent/>
        </Link>
      );
    } else {
      return (
        <div
          className={`${styles.imageContainer} ${inMasthead ? styles.inMasthead : ''}`}>
          <InnerContent/>
        </div>
      );
    }
  };

  return (pageData.backgroundImage?.[0]?.url && pageData.smallScreenBackgroundImage?.[0]?.url) &&
    <section>
      <SectionContent/>
    </section>;
};

export default FreeFormModule;
