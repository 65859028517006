import { ReactNode } from 'react';
import { ResolvedPageThemes } from '@/utils/theming-system';
import SiteHeader from '@/components/global/SiteHeader';

import styles from './MastheadContentWrapper.module.scss';

type Props = {
  children: ReactNode,
  hasMastheadContent: boolean,
  isTopLevelPage: boolean,
  pageTitle?: string,
  propertyId?: string | number,
  resolvedPageThemes: ResolvedPageThemes,
};

export default function MastheadContentWrapper({
  children,
  hasMastheadContent,
  isTopLevelPage,
  pageTitle,
  propertyId,
  resolvedPageThemes,
}: Props) {
  return <>
    <div
      className={`${styles.outerWrapper} ${(propertyId ? styles.hasPropertyLogo : '')}`}
      data-theme-context={hasMastheadContent ? 'masthead' : ''}
    >
      <SiteHeader
        pageTitle={pageTitle}
        isTopLevelPage={isTopLevelPage}
        propertyId={propertyId}
        resolvedPageThemes={resolvedPageThemes}
      />

      {hasMastheadContent && <>
        <div className={styles.image} />

        <div
          data-cy='masthead-content'
          className={`${styles.contentWrapper} contain`}>
          {children}
        </div>
      </>}
    </div>
  </>;
}
