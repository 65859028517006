import { ReactElement } from 'react';
import { defaultElement } from '@/components/modules/BodyContentModule';
import { BodyContentCommonModuleType } from '@/components/modules/BodyContentCommonModule';
import { BodyContentModulesGameSet, BodyContentModulesGamesCollage, Game } from '@/types/pbskids-graph';
import GameSet from '@/components/modules/GameSetModule';
import { ResolvedPageThemes } from '@/utils/theming-system';
import Logger from '@/utils/logger/base';

const logger = new Logger({ caller: 'components.modules.BodyContentGameModule' });

export type BodyContentGameModuleType =
  BodyContentCommonModuleType |
  BodyContentModulesGamesCollage |
  BodyContentModulesGameSet;

// Remove games that don't have requisite content to build a card
const filterGames = (games: Game[], module: BodyContentGameModuleType ) => {
  if (games?.length) {
    return games.filter((game) => game.mezzanine?.length && game.websiteUrl);
  } else {
    logger.warn(`Game entries not found on ${module.__typename}. Returned data: ${JSON.stringify(games, null, 2)}`);
    return [];
  }
};

export default function BodyContentGameModule({ module }: {
  module: BodyContentGameModuleType,
  themeData?: ResolvedPageThemes,
}): ReactElement {
  switch (module.__typename) {
    case 'BodyContentModulesGamesCollage': {
      // Replace when Games Collage Module collection entries are available
      return defaultElement;
    }

    case 'BodyContentModulesGameSet': {
      if (!module.collection || !module.collection[0]) {
        return defaultElement;
      }

      const games = module.collection[0].entries as Game[];

      const moduleData = {
        games: filterGames(games, module),
        title: module.collection[0].title as string,
      };

      return <GameSet
        games={ moduleData.games }
        title={ moduleData.title }
      ></GameSet>;
    }

    default:
      return defaultElement;
  }
}
