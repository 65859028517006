import React from 'react';
import { Game } from '@/types/pbskids-graph';
import GameCard from '@/components/modules/GameCard';
import styles from './GameSet.module.scss';

interface Props {
  games?: Game[],
  title?: string,
}

const imageSizes = '(min-width: 1024px) 225px, (min-width: 768px) 300px, 360px';

const GameSet: React.FC<Props> = ({
  games,
  title,
}) => {
  const GamesList = () => {
    if (games?.length) {
      return <>
        {games.map((item: Game, index: number) => (
          <li
            key={ index }
          >
            <GameCard
              gameItem={ item }
              sizes={ imageSizes }
            ></GameCard>
          </li>
        ))}
      </>;
    } else return <></>;
  };

  return ( games && games.length ) &&
    <section className={styles.gamesGrid}>
      { title &&
        <h2 className={ styles.title }>
          { title }
        </h2>
      }

      <ul className={ styles.gamesList }>
        <GamesList/>
      </ul>
    </section>;
};

export default GameSet;
