import { Game } from '@/types/pbskids-graph';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './GameCard.module.scss';
import { singleTransparentPixel } from '@/utils/theming-system';
interface Props {
  gameItem: Game,
  sizes?: string,
}

const GameCard: React.FC<Props> = ({
  gameItem,
  sizes,
}) => {
  const linkUrl = gameItem?.websiteUrl || '';
  const imageUrl = gameItem?.mezzanine?.[0]?.url;
  const alt = gameItem?.mezzanine?.[0]?.altText || gameItem?.title || '';
  return (
    <>
      { linkUrl && imageUrl && <article
        data-hover-group='game-card'
        className={styles.gameCard}>
        <div data-use-loading-dots>
          <Link
            href={ linkUrl }
          >
            <Image
              src={ imageUrl || singleTransparentPixel }
              alt={ alt }
              width={400}
              height={400}
              sizes={ sizes || '300px' }
            />
          </Link>
        </div>
      </article>}
    </>
  );
};
export default GameCard;
